<template lang="html">
  <div class="text-center mt-5 pt-5">
    <h1>Ooops!</h1>
    <h3>The page you were looking for is not here.</h3>
    <v-btn class="primary my-3" router :to="{ name: 'dashboard'}">Back to dashboard</v-btn>
    <v-row class="my-3" align="center" justify="center">
      <v-img
        src="../assets/addition-black-and-white-black-and-white-374918.jpg"
        aspect-ratio="1"
        class="grey lighten-2"
        max-width="500"
        max-height="300"
      ></v-img>
    </v-row>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
